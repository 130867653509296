import axios, { AxiosInstance } from "axios";
import { useUserStore } from "./store/user";

const HTTP: AxiosInstance = axios.create({
  baseURL: `https://api.livrearbitre.fr/`,
});

HTTP.interceptors.request.use((config) => {
  const store = useUserStore();
  if (store.user && store.user.accessToken) {
    const token = store.user.accessToken;
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

HTTP.interceptors.response.use(
  (response) => {
    // n’importe quel code de réponse HTTP dans la plage 2xx activera cette
    // fonction
    // faire quelque chose avec les données de la réponse
    return response;
  },
  function (error) {
    // n’importe quel code de réponse HTTP hors de la plage 2xx activera cette
    // fonction
    // faire quelque chose avec les données de l’erreur
    // console.log("Axios error", error);
    if (error.response.status == 401) {
      const store = useUserStore();
      store.logout();
      //return Promise.reject(error);
    }
    return Promise.reject(error);
    // const store = useUserStore();
    // store.logout();
    //return Promise.reject(error);
  }
);

export { HTTP };


import { Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

// @Options({
//   components: {
//     HelloWorld,
//   },
// })

interface Item {
  title: string;
  content: string;
  image: string;
}

export default class HomeView extends Vue {
  slide3items: Item[] = [
    {
      title: "Multilingue",
      content:
        "La gestion des différentes langues est prise en charge nativement. Ecrivez simplement vos histoires dans les langues dans lesquelles vous souhaitez les rendre disponibles.",
      image: require("@/assets/icons/trad.svg"),
    },
    {
      title: "Facile à prendre ne main",
      content:
        "Nul besoin de savoir coder, savoir écrire et utiliser une souris vous suffira. Nos outils sont pensés pour répondre à vos besoins de la plus simple des manières.",
      image: require("@/assets/icons/easy.svg"),
    },
    {
      title: "Audience globale ou restreinte",
      content:
        "Que vous souhaitiez diffuser vos histoires à toute la communauté, partager vos écrits à quelques invités ou encore créer une aventure de type JdR pour la partager avec vos amis, c'est vous qui choisissez.",
      image: require("@/assets/icons/private.svg"),
    },
    {
      title: "Evenements suivant la localisation",
      content:
        "Vous souhaitez déclencher un évènement près d'un lieu ou lorsque le lecture a parcouru une certaine distance ? Nos outils vous le permettent et vos histoires seront encore plus immersives.",
      image: require("@/assets/icons/localisation.svg"),
    },
  ];
  slide2items: Item[] = [
    {
      title: "Différents types d'histoires",
      content:
        "Plusieurs formats d'histoire vous seront proposés, de l'histoire horrifique à l'histoire policière en passant par du fantastique ou encore de la fantasy !",
      image: require("@/assets/icons/books.svg"),
    },
    {
      title: "Interactifs",
      content:
        "Vous avez le contrôle sur l'histoire que vous lisez et elle évoluera en fonction de vos décisions",
      image: require("@/assets/icons/interactive.svg"),
    },
    {
      title: "Echanges types SMS",
      content:
        "Pour certaines histoires vous pourrez etre amenés à échanger des messages avec les protagonistes et à attendre leurs réponses, ou pas...",
      image: require("@/assets/icons/sms.svg"),
    },
    {
      title: "Différentes branches narratives",
      content:
        "Vos choix ont des répercussions. Choisir, c'est renoncer et parfois vous ne pourrez explorer certains scnéarios qu'en renonçant à certains possibilités.",
      image: require("@/assets/icons/branch.svg"),
    },
    {
      title: "Résolvez des énigmes",
      content:
        "Venez résoudre des mystères, des casses tête, des énigmes en tout genre, avec pour seules limites celles des auteurs. Laissez vous surprendre et venez découvrir de nouveaux univers.",
      image: require("@/assets/icons/puzzle.svg"),
    },
    {
      title: "Le temps passe",
      content:
        "Dans certaines histoires vous découvrirez que le monde continue d'avancer même quand vous dormez. Laissez les protagonistes venir vous surprendre à n'importe quel moment de la journée.",
      image: require("@/assets/icons/time.svg"),
    },
    {
      title: "Lisez et jouez à plusieurs",
      content:
        "L'interactivité n'est pas la seule force des histoires que vous viendrez lire, car vous pourrez aussi les vivre à plusieurs, avec des amis ou de parfaits inconnus.",
      image: require("@/assets/icons/multi.svg"),
    },
    // { title: "", content: "" },
  ];
}

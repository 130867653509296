import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AuthorRoot from "../views/AuthorSubLayout.vue";
import StoryView from "../views/StoryView.vue";
import CreateStoryView from "../views/CreateStoryView.vue";
import StoriesView from "../views/StoriesView.vue";

import StepsView from "../views/StepsView.vue";
import HandleStepView from "../views/HandleStepView.vue";
import CreateEventView from "../views/CreateEventView.vue";
import EventsView from "../views/EventsView.vue";
import ReadingsView from "../views/ReadingsView.vue";
import CatalogView from "../views/CatalogView.vue";
import ReadView from "../views/ReadView.vue";

import ActivationAccount from "../views/ActivationAccount.vue";
import TestView from "../views/TestView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/activation/:activationKey",
    component: ActivationAccount,
    props: true,
  },
  {
    path: "/author",
    name: "authorStories",
    component: StoriesView,
  },
  {
    path: "/create",
    name: "create",
    component: CreateStoryView,
  },
  {
    path: "/story",
    component: AuthorRoot,
    props: true,
    children: [
      {
        path: ":storyId/step/:stepId?",
        name: "step",
        component: HandleStepView,
        props: true,
      },
      {
        path: ":storyId/steps",
        name: "steps",
        component: StepsView,
        props: true,
      },
      {
        path: ":storyId",
        name: "update",
        component: StoryView,
        props: true,
      },
      {
        path: ":storyId/events",
        name: "events",
        component: EventsView,
        props: true,
      },
      {
        path: ":storyId/event/:eventId?",
        name: "event",
        component: CreateEventView,
        props: true,
      },
    ],
  },
  {
    path: "/readings",
    name: "readings",
    component: ReadingsView,
  },
  {
    path: "/read/:storyId",
    name: "reader",
    component: ReadView,
    props: true,
  },
  {
    path: "/catalog",
    name: "catalog",
    component: CatalogView,
  },
  {
    path: "/test",
    component: TestView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { defineStore } from "pinia";
import { HTTP } from "../http-common";
import router from "../router";
import { jwtDecode } from "jwt-decode";

interface User {
  userFirstName: string;
  userLastName: string;
  accessToken: string;
  roles: Array<string>;
}

interface UserStoreState {
  user: User | null;
}

interface JwtPayload {
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": Array<string>;
}

export const useUserStore = defineStore({
  id: "user",
  state: (): UserStoreState => ({
    user: null,
  }),
  actions: {
    async login(email: string, password: string): Promise<boolean> {
      try {
        const response = await HTTP.post("/credentials", {
          username: email,
          password: password,
        });
        const token = response.data.accessToken;
        const decoded = jwtDecode<JwtPayload>(token);
        this.setUser(
          response.data,
          decoded[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ]
        );
        localStorage.setItem("user", JSON.stringify(this.user));
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    setUser(user: User, roles: Array<string>) {
      this.user = {
        userLastName: user.userLastName,
        accessToken: user.accessToken,
        userFirstName: user.userFirstName,
        roles: roles,
      };
    },
    // Méthode pour restaurer les données utilisateur à partir du localStorage
    onInit() {
      const userData = localStorage.getItem("user");
      if (userData) {
        this.user = JSON.parse(userData);
      }
    },
    // Méthode pour supprimer les données utilisateur du localStorage lors de la déconnexion
    logout() {
      localStorage.removeItem("user");
      this.user = null;
      router.push({ name: "home" });
    },
  },
});

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import french from "element-plus/dist/locale/fr.mjs";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import { setupI18n } from "./i18n"; // Importez votre fonction setupI18n

const pinia = createPinia();

const app = createApp(App);
setupI18n(app);
app.component("QuillEditor", QuillEditor);
app.use(router).use(ElementPlus).use(pinia).mount("#app");
